/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import useSWR from 'swr';
import { FiChevronDown } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { subMinutes, subHours, formatISO } from 'date-fns';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { useNetworkState } from '@uidotdev/usehooks';
import { RxChevronRight } from 'react-icons/rx';
import useUser from '../../../hooks/useUser';
import { serviceInstance } from '../../../lib/axios';
import DashboardLayout from '../../../components/layout/dashboardLayout';
import Spinner from '../../../components/loading';
import {
  OfflineIcon,
  OnlineIcon,
  RetryConnection,
} from '../../../components/stateIcons';

const timesInvertal = [6, 12, 24, 72];

export default function IncidentesView() {
  const navigate = useNavigate();
  const network = useNetworkState();
  const { user, setSelectedProfile, selectedProfile } = useUser();
  const [selectedInterval, setSelectedInterval] = useState(72);
  const [openFilter, setOpenFilter] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    setSelectedProfile(option);
    setIsOpen(false);
  };

  const calculateIntervalStart = (end) => {
    const now = new Date();
    let intervalStart;
    if (selectedInterval === 30) {
      intervalStart = subMinutes(now, selectedInterval);
    } else {
      intervalStart = subHours(now, selectedInterval);
    }
    return formatISO(intervalStart);
  };

  const {
    data: incidents,
    mutate,
    isValidating,
  } = useSWR(
    user?.api_key && selectedProfile
      ? [
          '/incidents_summary',
          selectedProfile?.id_cliente,
          selectedProfile?.time_zone,
        ]
      : null,
    async ([url, id, timezone]) => {
      const response = await serviceInstance.post(
        url,
        {
          id_cliente: id,
          interval_start: calculateIntervalStart(),
          interval_end: formatISO(new Date()),
          timezone,
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data.data;
    },
  );

  const { data: servicesConfig, isValidating: servicesIsValidating } = useSWR(
    user?.api_key && selectedProfile
      ? [
          '/services_config',
          selectedProfile?.id_cliente,
          selectedProfile?.time_zone,
        ]
      : null,
    async ([url, id, timezone]) => {
      const response = await serviceInstance.post(
        url,
        {
          id_cliente: id,
          timezone,
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data.data;
    },
    {
      refreshInterval: 1000 * 30,
    },
  );

  const handleSelectFilter = (option) => {
    setSelectedInterval(option);
    setOpenFilter(false);
    setTimeout(() => {
      mutate();
    }, 1000);
  };

  return (
    <DashboardLayout>
      <div>
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center space-x-2">
            {network.online ? <OnlineIcon /> : <OfflineIcon />}
            <div className="relative inline-block w-full text-white">
              <button
                onClick={toggleDropdown}
                className="w-full flex justify-between items-center bg-gray-800 px-4 py-2 rounded-lg shadow-lg focus:outline-none"
              >
                <span>{selectedProfile?.organizacion}</span>
                <FiChevronDown className="ml-2" />
              </button>
              {isOpen && (
                <div className="absolute left-0 w-full mt-2 bg-gray-700 rounded-lg shadow-lg z-50">
                  {user?.profiles.map((option) => (
                    <button
                      key={option.id_cliente}
                      onClick={() => handleSelect(option)}
                      className="w-full text-left px-4 py-2 hover:bg-gray-600 focus:outline-none"
                    >
                      {option.organizacion}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="w-6 h-6 text-white">
            {/* Placeholder para el ícono de filtro */}
          </div>
        </div>

        <h2 className="text-3xl font-bold text-white">Incidentes</h2>

        <div className="flex flex-row justify-end items-center">
          <div className="relative inline-block text-white">
            <button
              onClick={() => setOpenFilter(!openFilter)}
              className="w-full flex justify-between items-center border bg-gray-800 px-4 py-2 rounded-lg shadow-lg focus:outline-none"
            >
              <span>
                {' '}
                {selectedInterval !== 30
                  ? `${selectedInterval} horas`
                  : '30 minutos'}
              </span>
              <FiChevronDown className="ml-2" />
            </button>
            {openFilter && (
              <div className="absolute z-50 left-0 w-full mt-2 bg-gray-700 rounded-lg shadow-lg">
                {timesInvertal?.map((option) => (
                  <button
                    key={option}
                    onClick={() => handleSelectFilter(option)}
                    className="w-full text-left px-4 py-2 hover:bg-gray-600 focus:outline-none"
                  >
                    {option !== 30 ? `${option} horas` : '30 minutos'}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="space-y-4 h-[64vh] bg-[#131B23] overflow-y-auto p-4 mt-8">
          {network.online ? (
            incidents?.length !== 0 ? (
              !isValidating && !servicesIsValidating ? (
                incidents?.map((incident) => {
                  const service = servicesConfig?.find(
                    (service) => service.id_test === incident.id_test,
                  );

                  const monitorFamily = servicesConfig?.find(
                    (service) => service.id_test === incident.id_test,
                  )?.display_logo;

                  const label = service?.nombre || 'Servicio no encontrado';

                  return (
                    <button
                      id={incident.id_test}
                      onClick={() =>
                        navigate(
                          `/dashboard/incidents/${incident.id_test}?interval_start=${calculateIntervalStart()}&interval_end=${formatISO(new Date())}&displayLogo=${monitorFamily}`,
                        )
                      }
                      className={classNames(
                        'flex flex-row justify-between gap-4 p-4 rounded-xl items-center text-white w-full bg-gray-800',
                        {
                          'bg-red-600': incident.incidentes_abiertos > 0,
                        },
                      )}
                    >
                      <div className="flex flex-col gap-2 text-start">
                        <h3 className="font-bold">{label}</h3>
                        <p>
                          Incidentes abiertos: {incident.incidentes_abiertos}
                        </p>
                        <p>
                          Incidentes cerrados: {incident.incidentes_cerrados}
                        </p>
                      </div>
                      <button className="rounded-full bg-transparent">
                        <RxChevronRight color="white" size={32} />
                      </button>
                    </button>
                  );
                })
              ) : (
                <Spinner />
              )
            ) : (
              <div className="flex justify-center items-center text-white mt-16">
                <p>Sin incidentes en el período</p>
              </div>
            )
          ) : (
            <RetryConnection />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
