import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Line,
  LineChart,
} from 'recharts';
import React, { useState, useMemo, useEffect } from 'react';
import useSWR from 'swr';
import { FiChevronDown } from 'react-icons/fi';
import { toZonedTime } from 'date-fns-tz';
import { subMinutes, subHours, formatISO } from 'date-fns';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RxChevronLeft } from 'react-icons/rx';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import useLocationConfig from '../../../hooks/useLocationConfig';
import useUser from '../../../hooks/useUser';
import { serviceInstance } from '../../../lib/axios';
import DashboardLayout from '../../../components/layout/dashboardLayout';
import Spinner from '../../../components/loading';

const timesInvertal = [30, 6, 12, 24, 72];

const EvolutionDetails = () => {
  const navigate = useNavigate();
  const [selectedInterval, setSelectedInterval] = useState(30);
  const configData = useLocationConfig();

  // eslint-disable-next-line camelcase
  const { id: id_test } = useParams();
  const [searchParams, _] = useSearchParams();
  const { user, selectedProfile, setSelectedProfile } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [openErrors, setOpenErrors] = useState(true);
  const [openErrorMessages, setOpenErrorMessages] = useState(true);
  const [openTransactions, setOpenTransactions] = useState(true);
  const [openTransactionsByStatus, setOpenTransactionsByStatus] =
    useState(true);
  const [openVisitors, setOpenVisitors] = useState(true);
  const [openExpired, setOpenExpired] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);

  const calculateIntervalStart = (end) => {
    const now = new Date();
    let intervalStart;
    if (selectedInterval === 30) {
      intervalStart = subMinutes(now, selectedInterval);
    } else {
      intervalStart = subHours(now, selectedInterval);
    }
    return formatISO(intervalStart);
  };

  const {
    data: evolutionServices,
    mutate,
    isValidating,
  } = useSWR(
    user?.api_key && selectedProfile?.time_zone
      ? [
          '/singleservice_evolution',
          selectedProfile.id_cliente,
          selectedProfile.time_zone,
        ]
      : null,
    async ([url, id, timezone]) => {
      const response = await serviceInstance.post(
        url,
        {
          id_cliente: id,
          // eslint-disable-next-line camelcase
          id_test,
          timezone,
          interval_end: formatISO(new Date()),
          interval_start: calculateIntervalStart(),
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data;
    },
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    },
  );

  const handleSelectFilter = (option) => {
    setSelectedInterval(option);
    setOpenFilter(false);
    mutate();
  };

  const getStatusColor = (className, group) => {
    switch (className) {
      case 'ok':
        return 'bg-[#008000] z-[10] absolute';
      case 'warning':
        return 'bg-yellow-400 z-[20] absolute';
      case 'critical':
        if (group !== 0) {
          return 'bg-[#ff0000] z-[30] absolute';
        }
        return 'bg-[#ffa500] z-[30] absolute';
      case 'down':
        return 'bg-[#ff0000] z-[50] absolute';
      default:
        return 'bg-gray-300';
    }
  };

  useEffect(() => {
    const interval = localStorage.getItem('selectedInterval');
    if (interval) {
      setSelectedInterval(parseInt(interval, 10));
    }
  }, [selectedProfile]);

  const processTimelineData = (items) => {
    const groupedData = {};

    items.forEach((item) => {
      if (!groupedData[item.group]) {
        groupedData[item.group] = [];
      }
      groupedData[item.group].push(item);
    });

    return groupedData;
  };

  const calculateSegmentWidth = (start, end, totalStart, totalEnd) => {
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();
    const totalStartTime = new Date(totalStart).getTime();
    const totalEndTime = new Date(totalEnd).getTime();
    const totalDuration = totalEndTime - totalStartTime;

    return ((endTime - startTime) / totalDuration) * 100;
  };

  // Generate time labels based on timeRange
  const generateTimeLabels = () => {
    if (!selectedProfile.time_zone) {
      return null;
    }
    const labels = [];
    const now = new Date(toZonedTime(new Date(), selectedProfile.time_zone));
    let endTime;

    if (selectedInterval === 30) {
      endTime = subMinutes(now, 0);
      // eslint-disable-next-line no-plusplus
      for (let i = 6; i >= 0; i--) {
        const time = subMinutes(endTime, i * 5);
        labels.push(
          <div key={i} className="text-xs text-white">
            {`${String(time.getHours()).padStart(2, '0')}:${String(
              time.getMinutes(),
            ).padStart(2, '0')}`}
          </div>,
        );
      }
    } else {
      endTime = subHours(now, 0);
      const step = selectedInterval / 6;
      const endHour = endTime.getHours();

      for (let i = 6; i >= 0; i -= 1) {
        const hour = (endHour - i * step + 24) % 24;
        labels.push(
          <div key={i} className="text-xs text-white">
            {String(Math.abs(hour)).padStart(2, '0')}:00
          </div>,
        );
      }
    }
    return labels;
  };

  const groupedData = useMemo(
    () =>
      evolutionServices?.timevis_data?.items
        ? processTimelineData(evolutionServices?.timevis_data?.items)
        : {},
    [evolutionServices, selectedInterval],
  );

  useEffect(() => {
    mutate();
  }, [selectedInterval]);

  if (evolutionServices?.timevis_data)
    return (
      <DashboardLayout>
        <div className="flex items-center justify-between mb-8">
          <div className="flex flex-row gap-2">
            <button
              className="flex flex-row items-center gap-2 text-white"
              onClick={() => navigate(-1)}
              tabIndex="0"
            >
              <RxChevronLeft className="size-6 text-4xl" />
            </button>
            <div className="text-white">
              <h1 className="text-3xl font-bold">{searchParams.get('name')}</h1>
              <span>Evolución</span>
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-8 h-8 bg-gray-700 rounded-full flex items-center justify-center">
              <ReactSVG src="/svgs/robot.svg" />
            </div>
          </div>
        </div>

        {/* Timeline */}
        <div className="flex flex-row justify-end items-center">
          <div className="relative inline-block text-white">
            <button
              onClick={() => setOpenFilter(!openFilter)}
              className="w-full flex justify-between items-center border bg-gray-800 px-4 py-2 rounded-lg shadow-lg focus:outline-none"
            >
              <span>
                {' '}
                {selectedInterval !== 30
                  ? `${selectedInterval} horas`
                  : '30 minutos'}
              </span>
              <FiChevronDown className="ml-2" />
            </button>
            {openFilter && (
              <div className="absolute z-50 left-0 w-full mt-2 bg-gray-700 rounded-lg shadow-lg">
                {timesInvertal?.map((option) => (
                  <button
                    key={option}
                    onClick={() => handleSelectFilter(option)}
                    className="w-full text-left px-4 py-2 hover:bg-gray-600 focus:outline-none"
                  >
                    {option !== 30 ? `${option} horas` : '30 minutos'}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="space-y-4 h-[62vh] bg-[#131B23] overflow-y-auto p-4 mt-8">
          <div className="space-y-4">
            {!isValidating ? (
              Object.entries(groupedData).map(([groupId, items]) => {
                const sortedItems = items.sort(
                  (a, b) => new Date(a.start) - new Date(b.start),
                );

                const label = configData.find(
                  (item) => item.id_ubicacion === parseInt(groupId, 10),
                )?.nombre;

                const country = configData.find(
                  (item) => item.id_ubicacion === parseInt(groupId, 10),
                )?.pais;

                const firstItem = sortedItems[0];
                const lastItem = sortedItems[sortedItems.length - 1];

                return (
                  <div
                    key={groupId}
                    className="p-4 bg-gray-800 rounded-lg border border-gray-700"
                  >
                    <div className="flex flex-row gap-4 justify-between mb-2 text-white text-2xl font-semibold">
                      <p>{label ?? 'Estado consolidado'}</p>
                      {country && (
                        <img
                          src={`https://api.realuptime.net/flags/${country}.png`}
                          alt="flag"
                          className="h-6 w-[36px]"
                        />
                      )}
                    </div>
                    <button className="relative w-full">
                      <div className="flex flex-row h-6 rounded-md overflow-hidden relative">
                        {sortedItems.map((item, index) => {
                          const fillOutBar =
                            item.classname === 'down' && index === 0;

                          const withOverlay = item.classname === 'maintenance';

                          if (fillOutBar) {
                            return (
                              <div
                                key={item.start}
                                className={classNames(
                                  `${getStatusColor('down')} h-full absolute`,
                                )}
                                style={{
                                  width: `100%`,
                                }}
                              />
                            );
                          }

                          if (withOverlay) {
                            return (
                              <div
                                key={item.start}
                                className={classNames(`h-full z-[99] absolute`)}
                                style={{
                                  width: `${calculateSegmentWidth(item.start, item.end, firstItem.start, lastItem.end)}%`,
                                  left: `${calculateSegmentWidth(firstItem.start, item.start, firstItem.start, lastItem.end)}%`,
                                  opacity: 0.9,
                                  background: `repeating-linear-gradient( -45deg, rgba(0,0,0,0), rgba(0,0,0,0) 5px, gray 5px, gray 25px )`,
                                  backgroundSize: '2rem 2rem',
                                }}
                              />
                            );
                          }

                          return (
                            <div
                              key={item.start}
                              className={classNames(
                                `${getStatusColor(item.classname, item.group)} h-full`,
                              )}
                              style={{
                                width: `${calculateSegmentWidth(item.start, item.end, firstItem.start, lastItem.end)}%`,
                                left: `${calculateSegmentWidth(firstItem.start, item.start, firstItem.start, lastItem.end)}%`,
                              }}
                            />
                          );
                        })}
                      </div>
                      <div className="flex justify-between mt-1 px-1">
                        {generateTimeLabels()}
                      </div>
                    </button>
                  </div>
                );
              })
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </DashboardLayout>
    );

  return (
    <DashboardLayout>
      <div className="flex items-center justify-between mb-8">
        <div className="flex flex-row gap-2">
          <button
            className="flex flex-row items-center gap-2 text-white"
            onClick={() => navigate(-1)}
            tabIndex="0"
          >
            <RxChevronLeft className="size-6 text-4xl" />
          </button>
          <div className="text-white">
            <h1 className="text-3xl font-bold">{searchParams.get('name')}</h1>
            <span>Evolución</span>
          </div>
        </div>
        <div className="flex items-center">
          <div className="w-8 h-8 bg-gray-700 rounded-full flex items-center justify-center">
            <ReactSVG src="/svgs/privalytics.svg" />
          </div>
        </div>
      </div>

      <div className="h-[70vh] overflow-y-auto text-white">
        {isValidating ? (
          <Spinner />
        ) : (
          <div className="flex flex-col gap-y-8 ">
            <div>
              <button
                onClick={() => setOpenErrorMessages(!openErrorMessages)}
                className="w-full bg-[#162029] rounded-lg p-4 flex justify-between items-center text-left focus:outline-none"
              >
                <span className="text-lg font-semibold">
                  Mensajes de errores
                </span>
                <svg
                  className={`w-5 h-5 transform transition-transform ${
                    isOpen ? 'rotate-180' : ''
                  } text-gray-400`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {openErrorMessages && (
                <div className="rounded-sm bg-[#253544] text-white">
                  <div className="grid grid-cols-3 items-center gap-4 bg-slate-600 p-4">
                    <span className="text-lg font-semibold w-[5rem] truncate">
                      Nombre
                    </span>
                    <span className="text-lg font-semibold text-center">
                      Secuencia
                    </span>
                    <span className="text-lg font-semibold text-end">
                      Cantidad
                    </span>
                  </div>
                  {evolutionServices?.transaction_timevis_data?.tx_errores ? (
                    evolutionServices?.transaction_timevis_data?.tx_errores?.map(
                      (item, index) => (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          className="grid grid-cols-3 items-center gap-4 p-4"
                        >
                          <div>
                            <p className="text-lg font-semibold w-[5rem] truncate">
                              {item.name}
                            </p>
                          </div>
                          <div>
                            <p className="text-lg font-semibold text-center">
                              {item.secuencia}
                            </p>
                          </div>

                          <div>
                            <p className="text-lg font-semibold text-end">
                              {item.cantidad}
                            </p>
                          </div>
                        </div>
                      ),
                    )
                  ) : (
                    <div className="p-4 text-center">No hay errores</div>
                  )}
                </div>
              )}
            </div>

            <div>
              <button
                onClick={() => setOpenTransactions(!openTransactions)}
                className="w-full bg-[#162029] rounded-lg p-4 flex justify-between items-center text-left focus:outline-none"
              >
                <span className="text-lg font-semibold">
                  Transacciones por estado
                </span>
                <svg
                  className={`w-5 h-5 transform transition-transform ${
                    isOpen ? 'rotate-180' : ''
                  } text-gray-400`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {openTransactions && (
                <ResponsiveContainer
                  height={225}
                  width="100%"
                  className="rounded-sm bg-[#253544] p-4"
                >
                  <BarChart
                    barCategoryGap={50}
                    barGap={5}
                    data={evolutionServices?.transaction_timevis_data?.tx_stats?.map(
                      (item) => ({
                        error: item.num_error,
                        expiradas: item.num_expired,
                        ok: item.num_ok,
                        corriendo: item.num_running,
                      }),
                    )}
                  >
                    <CartesianGrid
                      strokeDasharray="1 1"
                      verticalCoordinatesGenerator={(props) =>
                        // eslint-disable-next-line react/prop-types
                        [67, 100, 150, 200, 250, 300, 350, 400]
                      }
                    />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend width="100%" />
                    <Bar
                      radius={[10, 10, 10, 10]}
                      dataKey="error"
                      fill="red"
                      activeBar={<Rectangle fill="pink" stroke="blue" />}
                      // eslint-disable-next-line react/no-unstable-nested-components
                      shape={(props) => <Rectangle {...props} width={6} />}
                    />
                    <Bar
                      radius={[10, 10, 10, 10]}
                      dataKey="expiradas"
                      fill="yellow"
                      activeBar={<Rectangle fill="gold" stroke="purple" />}
                      // eslint-disable-next-line react/no-unstable-nested-components
                      shape={(props) => <Rectangle {...props} width={6} />}
                    />
                    <Bar
                      radius={[10, 10, 10, 10]}
                      dataKey="ok"
                      fill="green"
                      strokeWidth="10"
                      activeBar={<Rectangle fill="gold" stroke="purple" />}
                      // eslint-disable-next-line react/no-unstable-nested-components
                      shape={(props) => <Rectangle {...props} width={6} />}
                    />
                    <Bar
                      radius={[10, 10, 10, 10]}
                      dataKey="corriendo"
                      fill="blue"
                      activeBar={<Rectangle fill="gold" stroke="purple" />}
                      // eslint-disable-next-line react/no-unstable-nested-components
                      shape={(props) => <Rectangle {...props} width={6} />}
                    />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>

            <div>
              <button
                onClick={() => setOpenErrors(!openErrors)}
                className="w-full bg-[#162029] rounded-lg p-4 flex justify-between items-center text-left focus:outline-none"
              >
                <span className="text-lg font-semibold">
                  Porcentaje errores
                </span>
                <svg
                  className={`w-5 h-5 transform transition-transform ${
                    isOpen ? 'rotate-180' : ''
                  } text-gray-400`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {openErrors && (
                <ResponsiveContainer
                  height={225}
                  className="rounded-sm bg-[#253544] p-4"
                >
                  <BarChart
                    barCategoryGap={50}
                    barGap={5}
                    data={evolutionServices?.transaction_timevis_data?.tx_stats?.map(
                      (item) => ({
                        error: item.num_error / item.num_tx,
                      }),
                    )}
                  >
                    <CartesianGrid
                      strokeDasharray="1 1"
                      verticalCoordinatesGenerator={(props) =>
                        // eslint-disable-next-line react/prop-types
                        [67, 100, 150, 200, 250, 300, 350, 400]
                      }
                    />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend width="100%" />
                    <Bar
                      radius={[10, 10, 10, 10]}
                      dataKey="error"
                      fill="#6D1818"
                      activeBar={<Rectangle fill="pink" stroke="blue" />}
                      // eslint-disable-next-line react/no-unstable-nested-components
                      shape={(props) => <Rectangle {...props} width={6} />}
                    />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>

            <div>
              <button
                onClick={() => setOpenExpired(!openExpired)}
                className="w-full bg-[#162029] rounded-lg p-4 flex justify-between items-center text-left focus:outline-none"
              >
                <span className="text-lg font-semibold">
                  Porcentaje expiradas
                </span>
                <svg
                  className={`w-5 h-5 transform transition-transform ${
                    isOpen ? 'rotate-180' : ''
                  } text-gray-400`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {openExpired && (
                <ResponsiveContainer
                  height={225}
                  width="100%"
                  className="rounded-sm bg-[#253544] p-4"
                >
                  <BarChart
                    barCategoryGap={50}
                    barGap={5}
                    data={evolutionServices?.transaction_timevis_data?.tx_stats?.map(
                      (item) => ({
                        expiradas: item.num_expired / item.num_tx,
                      }),
                    )}
                  >
                    <CartesianGrid
                      strokeDasharray="1 1"
                      verticalCoordinatesGenerator={(props) =>
                        // eslint-disable-next-line react/prop-types
                        [67, 100, 150, 200, 250, 300, 350, 400]
                      }
                    />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend width="100%" />
                    <Bar
                      dataKey="expiradas"
                      fill="#fff"
                      radius={[10, 10, 10, 10]}
                      activeBar={<Rectangle fill="pink" stroke="blue" />}
                      // eslint-disable-next-line react/no-unstable-nested-components
                      shape={(props) => <Rectangle {...props} width={6} />}
                    />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>

            <div>
              <button
                onClick={() => setOpenVisitors(!openVisitors)}
                className="w-full bg-[#162029] rounded-lg p-4 flex justify-between items-center text-left focus:outline-none"
              >
                <span className="text-lg font-semibold">Visitantes</span>
                <svg
                  className={`w-5 h-5 transform transition-transform ${
                    isOpen ? 'rotate-180' : ''
                  } text-gray-400`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {openVisitors && (
                <ResponsiveContainer
                  height={225}
                  width="100%"
                  className="rounded-sm bg-[#253544] p-4"
                >
                  <BarChart
                    barCategoryGap={50}
                    barGap={5}
                    data={evolutionServices?.transaction_timevis_data?.tx_visitors?.map(
                      (item) => ({
                        name: item.timestamp,
                        visitantes: item.num_visitors,
                      }),
                    )}
                  >
                    <CartesianGrid
                      strokeDasharray="1 1"
                      verticalCoordinatesGenerator={(props) =>
                        // eslint-disable-next-line react/prop-types
                        [67, 100, 150, 200, 250, 300, 350, 400]
                      }
                    />
                    <XAxis />
                    <YAxis />
                    <Tooltip />
                    <Legend width="100%" />
                    <Bar
                      radius={[10, 10, 10, 10]}
                      dataKey="visitantes"
                      fill="#fff"
                      activeBar={<Rectangle fill="pink" stroke="blue" />}
                      // eslint-disable-next-line react/no-unstable-nested-components
                      shape={(props) => <Rectangle {...props} width={6} />}
                    />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>

            <div>
              <button
                onClick={() =>
                  setOpenTransactionsByStatus(!openTransactionsByStatus)
                }
                className="w-full bg-[#162029] rounded-lg p-4 flex justify-between items-center text-left focus:outline-none"
              >
                <span className="text-lg font-semibold">Apdex</span>
                <svg
                  className={`w-5 h-5 transform transition-transform ${
                    isOpen ? 'rotate-180' : ''
                  } text-gray-400`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {openTransactionsByStatus && (
                <ResponsiveContainer
                  height={225}
                  width="100%"
                  className="rounded-sm bg-[#253544] p-4"
                >
                  <LineChart
                    width={600}
                    height={225}
                    data={evolutionServices?.transaction_timevis_data?.tx_apdex?.map(
                      (item) => ({
                        time: item.timestamp,
                        value: item.apdex,
                      }),
                    )}
                  >
                    <CartesianGrid
                      strokeDasharray="1 1"
                      verticalCoordinatesGenerator={(props) =>
                        // eslint-disable-next-line react/prop-types
                        [67, 100, 150, 200, 250, 300, 350, 400]
                      }
                    />
                    <XAxis dataKey="time" />
                    <YAxis domain={[0, 1]} />
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#253544" />
                    <ReferenceLine
                      y={0.94}
                      label="Excelente"
                      stroke="darkgreen"
                      strokeDasharray="3 3"
                    />
                    <ReferenceLine
                      y={0.85}
                      label="Bueno"
                      stroke="lightgreen"
                      strokeDasharray="3 3"
                    />
                    <ReferenceLine
                      y={0.7}
                      label="Regular"
                      stroke="yellow"
                      strokeDasharray="3 3"
                    />
                    <ReferenceLine
                      y={0.49}
                      label="Deficiente"
                      stroke="orange"
                      strokeDasharray="3 3"
                    />
                    <ReferenceLine
                      y={0}
                      label="Malo"
                      stroke="darkred"
                      strokeDasharray="3 3"
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default EvolutionDetails;
